<template>
  <div class="incoming-order-list main-panel large">
    <h3 class="panel-title">来胚单列表</h3>
    <div class="top">
      <el-form inline>
        <el-form-item>
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              size="mini"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="danger" icon="el-icon-search" size="mini" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" height="500" v-loading="isLoading">
      <el-table-column align="center" label="日期">
        <template #default="scope">{{ $day(scope.row.incoming.date).format("YYYY-MM-DD") }}</template>
      </el-table-column>
      <el-table-column align="center" label="供应商" prop="incoming.supplierName"></el-table-column>
      <el-table-column align="center" label="仓库" prop="incoming.wareName"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <div class="options">
            <i class="update-icon el-icon-edit" title="修改" @click="openLayer(scope.row)"></i>
            <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="del(scope.row.incoming.id)">
              <template #reference>
                <i class="del-icon el-icon-close" title="删除"></i>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>

      <template #append>
        <div class="load-more"
             :class="{'no-more': noMore}"
             v-if="list.length"
             @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}</div>
      </template>
    </el-table>

    <my-layer v-if="Object.keys(layerInfo).length"
              :title="layerInfo.layerTitle"
              :component="layerComponent"
              @handle="layerInfo = {}; search();"
              :layer-info="layerInfo"
              @close="layerInfo = {}" />
  </div>
</template>

<script>
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {MyLayer},
  data() {
    return {
      dateRange: null,
      form: {
        currentPage: 1,
        pageSize: 50
      },
      list: [],
      isLoading: false,
      noMore: false,

      departments: [],

      layerComponent: undefined,
      layerInfo: {}
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    async search() {
      this.form.currentPage = 1
      this.noMore = false
      this.list = []
      await this.getList()
    },

    async getList() {
      if (this.noMore) return false;
      this.isLoading = true
      const sendData = {...this.form}
      this.dateRange && (sendData.startDate = this.dateRange[0])
      this.dateRange && (sendData.endDate = this.dateRange[1])

      const res = await this.$api.Stock.IncomingOrder.getList(sendData).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.form.currentPage += 1
        this.list.push(...res.data.data)

        if (this.list.length >= res.data.total) {
          this.noMore = true
        }
      } else {
        this.$message.error("获取列表失败")
      }
    },

    async openLayer(info = {}) {
      this.layerComponent = shallowRef((await import("@/views/stock/incoming-order/incoming-order")).default)
      this.layerInfo = {...info, layerTitle: "来胚单修改"}
    },

    async del(id) {
      this.isLoading = true
      const res = await this.$api.Stock.IncomingOrder.del(id).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success("删除成功")
        this.form.pageSize = this.list.length - 1
        await this.search()
        this.form.pageSize = this.$options.data().form.pageSize
      } else {
        this.$message.error(res?.msg || "删除失败")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "incoming-order-list";
</style>
